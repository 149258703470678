<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-01 14:33:54
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 17:08:36
-->
<template>
  <div class="balance-purchase">
    <div class="personal-center-title">
      采购结余
    </div>
    <div class="balance-purchase-body">
      <div class="balance-purchase-total">
        <div class="grid-content number-red">
          <p class="number">{{ $utils.formatMoney(userInfo.procurement) }}</p>
          <p class="text">采购余额</p>
        </div>
        <div class="balance-purchase-btn">
          <span>采购结余只支持购买商品，不支持提现等操作~</span>
        </div>
      </div>
      <div class="balance-purchase-detailed">
        <el-radio-group v-model="tabPosition" @change="gettWalletInfo">
          <el-radio-button label="-1">全部</el-radio-button>
          <el-radio-button label="1">收益</el-radio-button>
          <el-radio-button label="0">支出</el-radio-button>
        </el-radio-group>
        <el-table stripe :data="tableData" style="width: 100%">
          <el-table-column prop="createDate" label="交易时间">
          </el-table-column>
          <el-table-column prop="orderNum" label="交易单号"> </el-table-column>
          <el-table-column prop="state" label="收支类型">
            <template slot-scope="scope">
              <span>{{ scope.row.state == 0 ? "支出" : "收益" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="money" label="收支金额" width="150px">
            <template slot-scope="scope">
              <!-- <span>{{ $utils.formatMoney(scope.row.money) }}</span> -->
              <span v-show="scope.row.state == 1"
                >+￥{{ $utils.formatMoney(scope.row.money) }}</span
              >
              <span v-show="scope.row.state == 0"
                >-￥{{ $utils.formatMoney(scope.row.money) }}</span
              >
            </template>
          </el-table-column>
        </el-table>
        <u-pagination v-if="total > 0" :total="total" @change="changePagination"></u-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { session } from "@/utils/sessions";
import UPagination from "@/components/UPagination";

export default {
  components: {
    UPagination
  },
  data() {
    return {
      userInfo: {},
      tableData: [],
      tabPosition: "-1",
      total: 0,
      tableParams: {
        num: 10,
        page: 1
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getUserInfo();
    this.getWallet();
  },
  methods: {
    handleWithdrawal() {
      this.$refs.operation.init();
    },
    changePagination(page, pageSize) {
      this.tableParams = {
        num: pageSize,
        page: page
      };
      this.getWallet();
    },

    gettWalletInfo() {
      this.tableParams.page = 0;
      this.getWallet();
    },

    getUserInfo() {
      this.$api.getUserInfo({ id: this.userId }).then(user => {
        this.userInfo = user.data;
        session.setUsers(user.data);
        this.$store.dispatch("getUser", true);
      });
    },
    getWallet() {
      let params = {
        id: this.userId,
        payType: 2,
        state: this.tabPosition,
        ...this.tableParams
      };
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getWallet(params)
        .then(res => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
